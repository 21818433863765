<div class="auto-select-row">
    <div class="auto-select-input">
        <input #autoInput type="text" matInput [formControl]="inputField" 
            [matAutocomplete]="auto" 
            [matAutocompleteConnectedTo]="getConnectedElement()"
            (blur)="onBlur(auto.isOpen)" [matAutocompleteDisabled]="disabled"
            [attr.aria-describedby]="ariaDescribedBy"/>
    </div>
    
    <div class="auto-select-arrow-wrapper" (click)="downArrowClick($event)" role="presentation">
        <div class="auto-select-arrow">
            <!-- Use an inline SVG, because it works better than a CSS triangle in high contrast mode. -->
            <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false" aria-hidden="true">
                <path d="M7 10l5 5 5-5z"/>
            </svg>
        </div>
    </div>
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" (optionSelected)="optionSelected($event.option)"
    (closed)="autoClosed()" (opened)="autoOpened()">
    <mat-option *ngFor="let o of filteredOptions$ | async" [value]="getOptionValue(o)" [disabled]="isOptionDisabled(o)">
        <ng-template [ngIf]="optionContent?.template" [ngIfElse]="defaultOptionTemplate">
            <ng-container *ngTemplateOutlet="$any(optionContent?.template); context: {$implicit: o}"></ng-container>
        </ng-template>
        <ng-template #defaultOptionTemplate>
            {{ getOptionDisplay(o) }}
        </ng-template>
    </mat-option>
</mat-autocomplete>