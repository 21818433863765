// eslint-disable-next-line max-classes-per-file
import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, Component, ContentChild, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm, UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from "rxjs";
import { map, skip } from "rxjs/operators";

import { MultiOptionContentDirective } from "./multi-option-content.directive";

class MultiSelectBase {
    readonly stateChanges = new Subject<void>();
    constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl) { }
}

const autoSelectMixinBase = mixinErrorState(MultiSelectBase);

const getDisplayResult = <T>(item: T | null | undefined, displayFunc?: (opt: T | null | undefined) => string): string => {
    // Ideally we use the supplied display function.
    if (displayFunc) return displayFunc(item)?.toString() ?? "";
    if (item === null || item === undefined) return "";

    /* eslint-disable @typescript-eslint/no-explicit-any */
    // If we are not provided a display function, check if the option has a "toString" member and call that
    if ((item as any).toString) return (item as any).toString();
    // If we don't have a display function, we have no choice but to assume the option can be cast to a string.
    return item as any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
};

const defaultCompareWith = <TVal>(o1: TVal, o2: TVal) => o1 === o2;

@Component({
    selector: "app-multi-select",
    templateUrl: "./multi-select.component.html",
    styleUrls: ["./multi-select.component.scss"],
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: MatFormFieldControl, useExisting: MultiSelectComponent }]
})
export class MultiSelectComponent<TOpt = unknown, TVal = TOpt>
    extends autoSelectMixinBase
    implements DoCheck, OnInit, AfterViewInit, OnDestroy, MatFormFieldControl<TVal[]>, ControlValueAccessor {

    private static nextId = 0;

    /**
     * The result of this function will be used to bind the value of an option
     */
    @Input() optionValueFunc?: (opt: TOpt) => TVal;

    /**
     * The result of this function will be used to get the display string for an option.
     */
    @Input() optionDisplayFunc?: (opt: TOpt | null | undefined) => string;

    /**
     * The result of this function will be used to determine if an individual option is enabled
     */
    @Input() optionDisabledFunc?: (opt: TOpt) => boolean;

    /**
     * Comparison function to specify which option is displayed. Defaults to object equality.
     */
    @Input() compareWith: (o1: TVal, o2: TVal) => boolean = defaultCompareWith;

    /**
     * The list of options available
     */
    @Input() set options(value: TOpt[] | null) {
        this.optionsSubject.next(value ?? []);
    }

    @Output() valueChange = new EventEmitter<TVal[]>();

    @ContentChild(MultiOptionContentDirective) optionContent?: MultiOptionContentDirective<TOpt>;

    inputField = new UntypedFormControl();

    options$: Observable<TOpt[]>;
    selectedOptions$: Observable<TOpt[]>;

    //#region MatFormFieldControl implementation - properties
    /* eslint-disable @typescript-eslint/member-ordering */

    @HostBinding() id = `multi-select-input-${MultiSelectComponent.nextId++}`;

    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return this.focused || (!!this.inputField.value && this.inputField.value.length);
    }

    @Input() get placeholder(): string {
        return this._placeholder ?? "";
    }

    set placeholder(value: string) {
        this._placeholder = value;
    }

    @Input() get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    @Input() get disabled(): boolean {
        return this.inputField.disabled;
    }

    set disabled(value: boolean) {
        value = coerceBooleanProperty(value);
        if (value) {
            this.inputField.disable();
        } else {
            this.inputField.enable();
        }
        this.stateChanges.next();
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("aria-describedby") userAriaDescribedBy?: string;

    get value(): TVal[] {
        return this.selectedValueSubject.value;
    }

    set value(value: TVal[]) {
        value = value ?? [];
        this.inputField.setValue(value);
        this.selectedValueSubject.next(value);
        this.stateChanges.next();
    }

    get empty() {
        return !this.value.length;
    }

    focused = false;
    controlType = "multi-select-input";
    ariaDescribedBy?: string;

    @ViewChild("multiSelect") multiSelect: MatSelect | undefined;

    private _placeholder: string | undefined | null;
    private _required = false;

    //#endregion
    /* eslint-enable @typescript-eslint/member-ordering */

    private optionsSubject = new BehaviorSubject<TOpt[]>([]);
    private selectedValueSubject = new BehaviorSubject<TVal[]>([]);

    private selectedValueSub: Subscription | undefined;
    private focusSub: Subscription | undefined;

    private onChangedCallback?: (_: TVal[]) => void;
    private onTouchedCallback?: () => void;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        this.options$ = this.optionsSubject.asObservable();
        this.selectedOptions$ =
            combineLatest([this.optionsSubject, this.selectedValueSubject])
                .pipe(
                    map(([options, values]) => options
                        .filter(o => values.some(v => this.compareWith(v, this.getOptionValue(o)))))
                );
    }

    //#region MatFormFieldControl implementation - methods
    setDescribedByIds(ids: string[]) {
        this.ariaDescribedBy = ids.join(" ");
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== "mat-select") {
            setTimeout(this.openSelect);
        }
    }
    //#endregion

    //#region ControlValueAccessor implementation
    /* eslint-disable @typescript-eslint/no-explicit-any */
    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
    //#endregion

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnInit(): void {
        this.focusSub = this.fm.monitor(this.elRef, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
        this.selectedValueSub = this.selectedValueSubject.pipe(skip(1)).subscribe((value) => {
            this.valueChange.emit(value);
            this.onChangedCallback?.(value);
        });
    }

    ngAfterViewInit(): void {
        if (this.multiSelect) {
            this.multiSelect._positions = [
                {
                    originX: "start",
                    originY: "bottom",
                    overlayX: "start",
                    overlayY: "top",
                },
                {
                    originX: "start",
                    originY: "bottom",
                    overlayX: "start",
                    overlayY: "bottom",
                },
            ];
        }
    }

    ngOnDestroy(): void {
        this.focusSub?.unsubscribe();
        this.selectedValueSub?.unsubscribe();
        this.fm.stopMonitoring(this.elRef);
    }

    getOptionValue = (option: TOpt): TVal => {
        if (this.optionValueFunc) return this.optionValueFunc(option);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        // If we have no valueFunc, assume that TOpt === TVal
        return option as any;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    };

    getOptionDisplay = (option: TOpt | null | undefined): string => getDisplayResult(option, this.optionDisplayFunc);

    isOptionDisabled = (option: TOpt): boolean => this.optionDisabledFunc?.(option) ?? false;

    onBlur = () => {
        this.onTouchedCallback?.();
    };

    selectedOptionsChange = (value: TVal[]) => {
        this.value = value;
        this.onTouchedCallback?.();
    };

    removeOption = (option: TOpt): void => {
        this.value = this.value.filter(v => !this.compareWith(v, this.getOptionValue(option)));
        this.onTouchedCallback?.();
    };

    private openSelect = () => {
        this.multiSelect?.open();
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_required: BooleanInput;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
