import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DayOfWeek, GetChildNumberDto, NumberTargetType, SimpleUserDto, UpdateChildNumberDto } from "@api";
import { distinctUntilChanged, pairwise, Subscription } from "rxjs";

import { decimalValidator, greaterThanControl } from "~shared/util/custom-validators";
import { compareUsers } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

import { bindWeeklyTargets, getWeekTargetsValue, WeekTargetsForm } from "./week-targets-helper";

export interface DailyChildFormControls {
    updater: FormControl<SimpleUserDto | null>;
    targetLowerBound: FormControl<number | null>;
    targetUpperBound: FormControl<number | null>;
    weekTargets: WeekTargetsForm;
}

export type DailyChildForm = FormGroup<DailyChildFormControls>;

export type DailyChildrenForm = FormGroup<{ [day: string]: DailyChildForm }>;

type ChildNumberSettingsDto = Pick<GetChildNumberDto, "updater" | "target" | "weekTargets">;

const buildDailyChildForm = (
    weeks: number[],
    targetType: NumberTargetType,
    value?: ChildNumberSettingsDto,
    disabled = false,
): [DailyChildForm, Subscription] => {
    const hasLowerTarget = targetType !== NumberTargetType.belowTarget;
    const hasUpperTarget = targetType !== NumberTargetType.aboveTarget;
    const updaterControl = new FormControl<SimpleUserDto | null>(
        { value: value?.updater ?? null, disabled },
        [Validators.required]);

    const targetLowerBoundControl = new FormControl<number | null>(
        { value: value?.target.lowerBound ?? null, disabled: disabled || !hasLowerTarget },
        [decimalValidator]);

    const targetUpperBoundControl = new FormControl<number | null>(
        { value: value?.target.upperBound ?? null, disabled: disabled || !hasUpperTarget },
        [decimalValidator, greaterThanControl("targetLowerBound")]);

    const weekTargetsControl: WeekTargetsForm = new FormGroup({});

    const form: DailyChildForm = new FormGroup({
        updater: updaterControl,
        targetLowerBound: targetLowerBoundControl,
        targetUpperBound: targetUpperBoundControl,
        weekTargets: weekTargetsControl,
    });

    const sub = targetLowerBoundControl.valueChanges.subscribe(() => targetUpperBoundControl.updateValueAndValidity());
    bindWeeklyTargets(weeks, targetType, form.controls, weekTargetsControl, sub, value?.weekTargets, disabled);

    return [form, sub];
};

export const bindDailyChildren = (
    days: DayOfWeek[],
    weeks: number[],
    targetType: NumberTargetType,
    parentControls: DailyChildFormControls,
    dailyChildren: DailyChildrenForm,
    subscription: Subscription,
    children?: GetChildNumberDto[],
    disabled = false,
) => {
    const defaultValue: ChildNumberSettingsDto = {
        updater: parentControls.updater.value ?? undefined,
        target: {
            lowerBound: parentControls.targetLowerBound.value ?? undefined,
            upperBound: parentControls.targetUpperBound.value ?? undefined,
        },
        weekTargets: getWeekTargetsValue(weeks, targetType, parentControls.weekTargets),
    };
    for (const day of days) {
        const dayStr = day.toString();
        if (dayStr in dailyChildren.controls) continue;
        const child = children?.find(c => c.updateDay === day);
        const value: ChildNumberSettingsDto = child ?? defaultValue;
        const [dayForm, daySub] = buildDailyChildForm(weeks, targetType, value, disabled);
        dailyChildren.addControl(dayStr, dayForm);
        subscription.add(daySub);
        subscription.add(valueAndChanges(parentControls.updater).pipe(
            distinctUntilChanged(),
            pairwise(),
        ).subscribe(([oldUpdater, newUpdater]) => {
            const dayUpdater = dayForm.controls.updater.value;
            // When the main updater is changed, if the day updater was the same as the old updater, change it too.
            if (!dayUpdater || oldUpdater && compareUsers(dayUpdater, oldUpdater)) {
                dayForm.controls.updater.setValue(newUpdater);
            }
        }));
    }
};

export const forAllDays = (form: DailyChildrenForm, callback: (dayStr: string, child: DailyChildForm) => void) => {
    for (const dayStr in form.controls) {
        if (!Object.prototype.hasOwnProperty.call(form.controls, dayStr)) continue;
        callback(dayStr, form.controls[dayStr]);
    }
};

export const getDailyChildrenValue = (
    days: DayOfWeek[],
    weeks: number[],
    targetType: NumberTargetType,
    form: DailyChildrenForm,
): UpdateChildNumberDto[] | undefined => {
    if (!days.length) return undefined;
    return days.map(day => {
        const dayStr = day.toString();
        const dayForm = form.controls[dayStr];
        return {
            updateDay: day,
            updaterUserId: dayForm.controls.updater.value?.userId ?? undefined,
            target: {
                lowerBound: dayForm.controls.targetLowerBound.value ?? undefined,
                upperBound: dayForm.controls.targetUpperBound.value ?? undefined,
            },
            weekTargets: getWeekTargetsValue(weeks, targetType, dayForm.controls.weekTargets),
        };
    });
};
