import { DecimalPipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

import { NumberType } from "~shared/enums";
import { getNumberPrefix, getNumberSuffix } from "~shared/util/number-helper";

const NUMBER_FORMAT = ".0";

@Pipe({
    name: "numberFormat",
    pure: true
})
export class NumberFormatPipe implements PipeTransform {

    private readonly innerPipe = new DecimalPipe(this.locale);

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
    ) { }

    transform(value: number | string, numberType?: NumberType): string | null;
    transform(value: null | undefined, numberType?: NumberType): null;
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    transform(value: number | string | null | undefined, numberType?: NumberType): string | null;
    transform(value: number | string | null | undefined, numberType?: NumberType): string | null {
        if (value == null) return null;

        const formattedValue = this.innerPipe.transform(value, NUMBER_FORMAT) || "";
        return getNumberPrefix(numberType ?? NumberType.normal) + formattedValue + getNumberSuffix(numberType ?? NumberType.normal);
    }
}
