// eslint-disable-next-line max-classes-per-file
import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    Component, ContentChild, DoCheck, ElementRef, EventEmitter, Host, HostBinding, Inject,
    Input, OnDestroy, OnInit, Optional, Output, Self, SkipSelf, ViewChild
} from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm, UntypedFormControl } from "@angular/forms";
import { MatAutocompleteOrigin, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatOption } from "@angular/material/core";
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from "rxjs";
import { map, skip, tap } from "rxjs/operators";

import { AutoOptionContentDirective } from "./auto-option-content.directive";

class AutoSelectBase {
    readonly stateChanges = new Subject<void>();
    constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl) { }
}

const autoSelectMixinBase = mixinErrorState(AutoSelectBase);

const getDisplayResult = <T>(item: T, displayFunc?: (opt: T) => string): string => {
    // Ideally we use the supplied display function.
    if (displayFunc) return displayFunc(item)?.toString() ?? "";
    if (item === null || item === undefined) return "";

    /* eslint-disable @typescript-eslint/no-explicit-any */
    // If we are not provided a display function, check if the option has a "toString" member and call that
    if ((item as any).toString) return (item as any).toString();
    // If we don't have a display function, we have no choice but to assume the option can be cast to a string.
    return item as any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
};

const AUTO_ITEM_HEIGHT_PX = 48;

/**
 * A component designed to simplify the pattern of using an autocomplete field to select items in lieu of a dropdown.
 */
@Component({
    selector: "app-auto-select",
    templateUrl: "./auto-select.component.html",
    styleUrls: ["./auto-select.component.scss"],
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: MatFormFieldControl, useExisting: AutoSelectComponent }]
})
export class AutoSelectComponent<TOpt = unknown, TVal = TOpt>
    extends autoSelectMixinBase
    implements DoCheck, OnInit, OnDestroy, MatFormFieldControl<TVal>, ControlValueAccessor {

    private static nextId = 0;

    /**
     * The result of this function will be used to bind the value of an option
     */
    @Input() optionValueFunc?: (opt: TOpt) => TVal;

    /**
     * The result of this function will be searched when filtering the list of options.
     */
    @Input() searchFunc?: (opt: TOpt) => string;

    /**
     * The result of this function will be used to get the display string for an option.
     */
    @Input() optionDisplayFunc?: (opt: TOpt | null | undefined) => string;

    /**
     * The result of this function will be used to determine if an individual option is enabled
     */
    @Input() optionDisabledFunc?: (opt: TOpt) => boolean;

    /**
     * Providing this function will allow generation of a display value from the initially set value.
     *
     * Note: if this is not provided and TVal is not a string/number, it will be assumed that TVal == TOpt
     */
    @Input() valueDisplayFunc?: (val: TVal | null | undefined) => string;

    /**
     * Comparison function to specify which option is displayed. Defaults to object equality.
     */
    @Input() compareWith?: (o1: TVal, o2: TVal) => boolean;

    /**
     * The unfiltered list of options available to search through
     */
    @Input() set options(value: TOpt[] | null | undefined) {
        this.optionsSubject.next(value ?? []);
    }

    /**
     * Whether all options should be shown on focus
     */
    get showAllOnFocus() {
        return this._showAllOnFocus;
    }

    @Input() set showAllOnFocus(value: boolean) {
        this._showAllOnFocus = coerceBooleanProperty(value);
    }

    @Output() valueChange = new EventEmitter<TVal | null>();

    @ContentChild(AutoOptionContentDirective) optionContent?: AutoOptionContentDirective<TOpt>;
    inputField = new UntypedFormControl();

    filteredOptions$: Observable<TOpt[]>;

    //#region MatFormFieldControl implementation - properties
    /* eslint-disable @typescript-eslint/member-ordering */

    @HostBinding() id = `auto-select-input-${AutoSelectComponent.nextId++}`;

    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input() get placeholder(): string {
        return this._placeholder ?? "";
    }

    set placeholder(value: string) {
        this._placeholder = value;
    }

    @Input() get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    @HostBinding("class.auto-select-disabled")
    @Input() get disabled(): boolean {
        return this.inputField.disabled;
    }

    set disabled(value: boolean) {
        value = coerceBooleanProperty(value);
        if (value) {
            this.inputField.disable();
        } else {
            this.inputField.enable();
        }
        this.stateChanges.next();
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("aria-describedby") userAriaDescribedBy?: string;

    get value(): TVal | null {
        return this.selectedValueSubject.value;
    }

    set value(value: TVal | null) {
        if (value === this.value) return;
        this.inputField.setValue(value);
        this.selectedValueSubject.next(value ?? null);
        this.stateChanges.next();
    }

    get empty() {
        const value = this.value;
        return value === null || value === undefined;
    }

    focused = false;
    controlType = "auto-select-input";
    ariaDescribedBy?: string;

    @ViewChild("autoInput") autoInput: ElementRef<HTMLInputElement> | undefined;
    @ViewChild("autoInput", { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger | undefined;

    private _placeholder: string | undefined | null;
    private _required = false;

    //#endregion
    /* eslint-enable @typescript-eslint/member-ordering */

    private _showAllOnFocus = true;

    private optionsSubject = new BehaviorSubject<TOpt[]>([]);
    private selectedValueSubject = new BehaviorSubject<TVal | null>(null);
    private filterTextSubject = new BehaviorSubject<string>("");

    private selectedValueSub: Subscription | undefined;
    private focusSub: Subscription | undefined;
    private inputTextSub: Subscription | undefined;
    private optionsSub: Subscription | undefined;

    private onChangedCallback?: (_: TVal | null) => void;
    private onTouchedCallback?: () => void;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Inject(MAT_FORM_FIELD) @Host() @SkipSelf() public formField: MatFormField,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        this.filteredOptions$ = combineLatest([
            this.optionsSubject,
            this.filterTextSubject,
        ]).pipe(
            map(([options, filter]) => this.filterOptions(options, filter))
        );
    }

    //#region MatFormFieldControl implementation - methods
    setDescribedByIds(ids: string[]) {
        this.ariaDescribedBy = ids.join(" ");
    }

    onContainerClick(event: MouseEvent) {
        if (this.disabled) return;
        if ((event.target as Element).tagName.toLowerCase() !== "input") {
            this.autoInput?.nativeElement?.focus();
            setTimeout(this.openDropdown);
        }
    }
    //#endregion

    //#region ControlValueAccessor implementation
    /* eslint-disable @typescript-eslint/no-explicit-any */
    writeValue(obj: any): void {
        if ((obj ?? null) === this.value) return;
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
    //#endregion

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnInit(): void {
        this.focusSub = this.fm.monitor(this.elRef, true).subscribe(origin => {
            if (!this.focused && !!origin && origin !== "program") {
                setTimeout(this.openDropdown);
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });
        this.selectedValueSub = this.selectedValueSubject.pipe(skip(1)).subscribe((value) => {
            this.valueChange.emit(value);
        });
        this.inputTextSub = this.inputField.valueChanges.pipe(tap(text => {
            if (!text && !this.required && !this.disabled && this.value !== null) {
                this.setValue(null);
            }
        })).subscribe(this.filterTextSubject);
        this.optionsSub = this.optionsSubject.subscribe(() => {
            // Recalculate the display value
            if (this.inputField.value) this.inputField.setValue(this.inputField.value);
        });
    }

    ngOnDestroy(): void {
        this.focusSub?.unsubscribe();
        this.selectedValueSub?.unsubscribe();
        this.inputTextSub?.unsubscribe();
        this.optionsSub?.unsubscribe();
        this.fm.stopMonitoring(this.elRef);
    }

    focus = () => {
        if (this.autoInput?.nativeElement) {
            this.autoInput.nativeElement.focus();
        }
    };

    displayWith = (value: TVal): string => {
        if (this.valueDisplayFunc) {
            return getDisplayResult(value, this.valueDisplayFunc);
        }

        if ((this.compareWith && !!value) || typeof (value) === "string" || typeof (value) === "number") {
            const options = this.optionsSubject.value ?? [];
            // Lookup the option in the options list
            const option = options.find(opt => this.compareWithInternal(this.getOptionValue(opt), value));
            // Return the display of the found option
            return this.getOptionDisplay(option);
        }

        /* eslint-disable @typescript-eslint/no-explicit-any */
        // Assume that TOpt === TVal
        return this.getOptionDisplay(value as any);
        /* eslint-enable @typescript-eslint/no-explicit-any */
    };

    getOptionValue = (option: TOpt): TVal => {
        if (this.optionValueFunc) return this.optionValueFunc(option);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        // If we have no valueFunc, assume that TOpt === TVal
        return option as any;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    };

    getOptionDisplay = (option: TOpt | null | undefined): string => getDisplayResult(option, this.optionDisplayFunc);

    isOptionDisabled = (option: TOpt): boolean => this.optionDisabledFunc?.(option) ?? false;

    optionSelected = (option: MatOption) => {
        if (!option) {
            if (!this.required) {
                this.setValue(null);
            }
            return;
        }
        this.setValue(option.value);
        if (this.autoInput?.nativeElement) {
            const value = this.autoInput.nativeElement.value;
            this.autoInput.nativeElement.setSelectionRange(value.length, value.length);
        }
    };

    autoClosed = () => {
        this.inputField.setValue(this.selectedValueSubject.value);
        this.stateChanges.next();
    };

    autoOpened = () => {
        if (this.showAllOnFocus) {
            this.selectOption();
        }
    };

    onBlur = (isAutoOpen: boolean) => {
        if (!isAutoOpen) this.autoClosed();
        this.onTouchedCallback?.();
    };

    downArrowClick = ($event: MouseEvent) => {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.disabled) return;
        this.autoInput?.nativeElement?.focus();
        this.autoInput?.nativeElement?.select();
        setTimeout(() => this.openDropdown(true));
    };

    getConnectedElement = (): MatAutocompleteOrigin =>
        new MatAutocompleteOrigin(this.formField ? this.formField.getConnectedOverlayOrigin() : this.elRef);

    private openDropdown = (fromArrow = false) => {
        if (this.disabled) return;
        let filterText: string;
        if (this.showAllOnFocus || fromArrow) {
            filterText = "";
        } else {
            filterText = this.value ? this.displayWith(this.value) : "";
        }
        this.filterTextSubject.next(filterText);
        this.autoTrigger?.openPanel();
    };

    private getSearchResult = (option: TOpt): string => getDisplayResult(option, this.searchFunc ?? this.optionDisplayFunc);

    private filterOptions = (options: TOpt[], filter: string): TOpt[] => {
        filter = (filter || "").toString().toLowerCase();
        const filterTokens = filter.split(/[\s,;]/).filter(x => !!x);
        return (options || []).filter(option => {
            const searchResult = this.getSearchResult(option).toLowerCase();
            return filterTokens.every(t => searchResult.includes(t));
        });
    };

    private selectOption = () => {
        if (!this.autoTrigger?.panelOpen || this.filterTextSubject.value) return;

        const currentValue = this.selectedValueSubject.value;
        const options = this.optionsSubject.value;
        if (!currentValue || !options || !options.length) return;

        const selectedIndex = options.findIndex(o => this.compareWithInternal(this.getOptionValue(o), currentValue));

        if (selectedIndex < 0) return;

        let topIndex = selectedIndex - 2;
        if (topIndex < 0) topIndex = 0;

        this.autoTrigger.autocomplete._setScrollTop(AUTO_ITEM_HEIGHT_PX * topIndex);

        // We need to delay this until after autocomplete automatically selects the first displayed item.
        setTimeout(() => {
            this.autoTrigger?.autocomplete._keyManager.setActiveItem(selectedIndex);
        });
    };

    private compareWithInternal = (o1: TVal, o2: TVal) => {
        if (this.compareWith) return this.compareWith(o1, o2);
        return o1 === o2;
    };

    private setValue = (value: TVal | null) => {
        this.selectedValueSubject.next(value);
        this.onChangedCallback?.(value);
        this.stateChanges.next();
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAllOnFocus: BooleanInput;
    static ngAcceptInputType_required: BooleanInput;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
