/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from "@angular/forms";
import { ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { BehaviorSubject, Subject, Subscription } from "rxjs";

import { DATE_FORMATS } from "~shared/util/constants";

const DATE_FORMAT_EXAMPLE_DATE = new Date(2023, 7, 4, 0, 0, 0, 0);

class DateFormatPickerBase {
    readonly stateChanges = new Subject<void>();
    constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl) { }
}

const dateFormatPickerMixinBase = mixinErrorState(DateFormatPickerBase);

@Component({
    selector: "app-date-format-picker",
    templateUrl: "./date-format-picker.component.html",
    styleUrls: ["./date-format-picker.component.scss"],
    providers: [{ provide: MatFormFieldControl, useExisting: DateFormatPickerComponent }]
})
export class DateFormatPickerComponent extends dateFormatPickerMixinBase
    implements DoCheck, OnInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {

    private static nextId = 0;

    @ViewChild(MatSelect) matSelectEl?: MatSelect;
    @Input() name?: string;

    readonly dateFormatExampleDate = DATE_FORMAT_EXAMPLE_DATE;
    readonly dateFormats = DATE_FORMATS;

    //#region MatFormFieldControl implementation - properties
    /* eslint-disable @typescript-eslint/member-ordering */

    @HostBinding() readonly id = `date-format-picker-${DateFormatPickerComponent.nextId++}`;

    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input() get placeholder(): string {
        return this._placeholder ?? "";
    }

    set placeholder(value: string) {
        this._placeholder = value;
    }

    @Input() get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    @Input() get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("aria-describedby") userAriaDescribedBy?: string;

    get value(): string | null {
        return this.valueSubject.value;
    }

    set value(value: string | null) {
        if (value === this.value) return;
        this.valueSubject.next(value ?? null);
        this.stateChanges.next();
    }

    get empty() {
        return !this.value;
    }

    focused = false;
    controlType?: string | undefined = "date-format-picker";
    ariaDescribedBy?: string;
    autofilled?: boolean | undefined;

    private _placeholder: string | undefined | null;
    private _required = false;
    private _disabled = false;

    //#endregion

    private readonly valueSubject = new BehaviorSubject<string | null>(null);
    private readonly subscriptions = new Subscription();

    private onChangedCallback?: (_: string | null) => void;
    private onTouchedCallback?: () => void;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.fm.monitor(this.elRef, true).subscribe(origin => {
                this.focused = !!origin;
                this.stateChanges.next();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    //#region MatFormFieldControl implementation - methods
    setDescribedByIds(ids: string[]): void {
        this.ariaDescribedBy = ids.join(" ");
    }

    onContainerClick(event: MouseEvent): void {
        if (this.disabled) return;
        this.matSelectEl?.focus();
        this.matSelectEl?.open();
    }
    //#endregion

    //#region ControlValueAccessor implementation
    /* eslint-disable @typescript-eslint/no-explicit-any */
    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
    //#endregion

    onBlur = () => {
        this.onTouchedCallback?.();
    };

    setValue = (value: string | null) => {
        this.value = value;
        this.onChangedCallback?.(value);
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_required: BooleanInput;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
